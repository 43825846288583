import React from "react"

import Layout from "../components/layout/layout"
import Row from "../components/row"
import Heading from "../components/heading"
import Button from "../components/button"

import l from "./../components/layout/layout.module.scss"

const NotFoundPage = () => (
  <Layout>
    <Row customClass={l._404}>
      <Heading type="h1" color="white">
        Whoops!
      </Heading>
      <p className={l.white_text}>
        De pagina die u zoekt bestaat helaas niet :(
      </p>
      <Button
        color="light"
        data={{ btn_link: "/", btn_label: "Ga naar homepage" }}
      />
    </Row>
  </Layout>
)

export default NotFoundPage
